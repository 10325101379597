import { ContactForm } from "./types/ContactForm";
import apiHeaders from "../utils/api-headers";
import { GetAxiosInstance } from "../utils/api-clients";
import endpoints, { LOCAL_API_PREFIX } from "../utils/endpoints";
import { Country } from "./types/Country";
import { QuoteForm } from "./types/QuoteForm";

export async function Contact(form: ContactForm): Promise<string> {
    const httpClient = await GetAxiosInstance(apiHeaders.JsonContent);
    const url = `${LOCAL_API_PREFIX}/${endpoints.Contact}`;
    return await httpClient
        .post(url, form)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export async function Quote(form: QuoteForm): Promise<string> {
    const httpClient = await GetAxiosInstance(apiHeaders.JsonContent);
    const url = `${LOCAL_API_PREFIX}/${endpoints.Quote}`;
    return await httpClient
        .post(url, form)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export async function GetCountries(): Promise<Country[]> {
    const httpClient = await GetAxiosInstance(apiHeaders.JsonContent);
    const url = `https://trial.mobiscroll.com/content/countries.json`;
    return await httpClient
        .get(url)
        .then(response => {
            const countries: Country[] = [];
            for (let i = 0; i < response.data.length; ++i) {
                const country = response.data[i];
                countries.push({ text: country.text, value: country.value });
            }
            return countries;
        })
        .catch(error => {
            throw error;
        });
}
