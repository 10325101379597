import "./CommercialPlanView.scss";
import React, { useState } from "react";
import Sprite from "../../../assets/icons/Sprite";
import CreateTrackAccountView from "../CreateTrackAccountView/CreateTrackAccountView";
import { useTranslation } from "react-i18next";

/**
 * @ignore
 */
export const stub = (): never => {
    throw new Error("You forgot to wrap your component in Provider.");
};

export interface NavStatusItem {
    title: string;
    subtitle: string;
}

function CommercialPlanView(): JSX.Element {
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const { t } = useTranslation();
    const navStatusItems: NavStatusItem[] = [
        {
            title: t("nav_create_account_title"),
            subtitle: t("nav_create_account_subtitle"),
        },
        {
            title: t("nav_payment_title"),
            subtitle: "",
        },
    ];

    return (
        <div className={"commercial-plan"}>
            <div className={"commercial-plan__nav"}>
                <div className={"commercial-plan__nav-title"}>
                    {navStatusItems[activeStepIndex].title}
                </div>
                <div className={"commercial-plan__nav-subtitle"}>
                    {navStatusItems[activeStepIndex].subtitle}
                </div>
                <div className={"commercial-plan__nav-status"}>
                    <div className={"commercial-plan__nav-status-indicator"}>
                        <Sprite
                            name={
                                activeStepIndex === 0
                                    ? "circleFilled"
                                    : "circleCompleted"
                            }
                        />
                        <div
                            className={"commercial-plan__nav-status-separator"}
                        ></div>
                        <Sprite
                            name={
                                activeStepIndex === 1
                                    ? "circleFilled"
                                    : activeStepIndex < 1
                                    ? "circle"
                                    : "circleCompleted"
                            }
                        />
                    </div>
                    <div className={"commercial-plan__nav-status-text"}>
                        <span>{navStatusItems[0].title}</span>
                        <span>{navStatusItems[1].title}</span>
                    </div>
                </div>
            </div>

            <div className={"commercial-plan__content"}>
                <CreateTrackAccountView />
            </div>
        </div>
    );
}

export default CommercialPlanView;
