import "./ContactsView.scss";
import PageTitle from "../../components/PageTitle";
import {
    Form,
    Grid,
    Dropdown,
    TextArea,
    Button,
    Input,
} from "semantic-ui-react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import React, { useState, useMemo } from "react";
import { ContactForm } from "../../api/contacts/types/ContactForm";
import { Contact, GetCountries } from "../../api/contacts/contacts-service";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes/Routes";
import { Country } from "../../api/contacts/types/Country";
import useLocationItems from "../../hooks/DropdownItems/LocationItems";
import { useTranslation } from "react-i18next";

function ContactsView(): JSX.Element {
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameError, setFirstNameError] = useState<boolean>(false);
    const [lastName, setLastName] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [companyError, setCompanyError] = useState<boolean>(false);
    const [country, setCountry] = useState<string>("");
    const [countryError, setCountryError] = useState<boolean>(false);
    const [location, setLocation] = useState<string>("");
    const [locationError, setLocationError] = useState<boolean>(false);
    const [help, setHelp] = useState<string>("");

    const { data: countries, isLoading: isCountriesLoading } = useQuery<
        Country[]
    >("Countries", async () => await GetCountries(), {
        staleTime: Infinity,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    const countryOptions = useMemo(() => {
        return countries?.map(item => ({
            key: item.text,
            text: item.text,
            value: item.text,
        }));
    }, [countries]);

    const locationOptions = useLocationItems();

    const history = useHistory();

    const contactMutation = useMutation(Contact, {
        onSuccess: async () => {
            history.push(Routes.Thanks);
        },
    });

    const contact = async (): Promise<void> => {
        let validForm = true;
        if (!firstName) {
            setFirstNameError(true);
            validForm = false;
        }

        if (!lastName) {
            setLastNameError(true);
            validForm = false;
        }

        if (!email) {
            setEmailError(true);
            validForm = false;
        }

        if (!company) {
            setCompanyError(true);
            validForm = false;
        }

        if (!country) {
            setCountryError(true);
            validForm = false;
        }

        if (!location) {
            setLocationError(true);
            validForm = false;
        }

        if (validForm) {
            const request: ContactForm = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                company: company,
                country: country,
                location: location,
                help: help,
            };
            await contactMutation.mutateAsync(request);
        }
    };

    const { t } = useTranslation();

    return (
        <div className={"contacts"}>
            <div className={"contacts__content"}>
                <PageTitle title={"Contact sales"} />
                <Form className={"contacts__form"}>
                    <Grid
                        columns={2}
                        className={"contacts__form-grid"}
                        stackable
                    >
                        <Grid.Column mobile={16} computer={8}>
                            <Form.Field
                                control={Input}
                                placeholder={t("input_placeholder_first_name")}
                                error={firstNameError}
                                onChange={event =>
                                    setFirstName(event.target.value)
                                }
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={8}>
                            <Form.Field
                                control={Input}
                                placeholder={t("input_placeholder_last_name")}
                                error={lastNameError}
                                onChange={event =>
                                    setLastName(event.target.value)
                                }
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={16}>
                            <Form.Field
                                control={Input}
                                placeholder={t("input_placeholder_email")}
                                error={emailError}
                                onChange={event => setEmail(event.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={6}>
                            <PhoneInput
                                country="gb"
                                onChange={value => setPhone(value)}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={5}>
                            <Form.Field
                                control={Input}
                                placeholder={t("input_placeholder_company")}
                                error={companyError}
                                onChange={event =>
                                    setCompany(event.target.value)
                                }
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={5}>
                            <Form.Field>
                                <Dropdown
                                    placeholder={t("input_placeholder_company")}
                                    error={countryError}
                                    options={countryOptions}
                                    fluid
                                    selection
                                    onChange={(_, data) =>
                                        setCountry(data.value as string)
                                    }
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={16}>
                            <Form.Field>
                                <Dropdown
                                    placeholder={t(
                                        "input_placeholder_business_type"
                                    )}
                                    error={locationError}
                                    options={locationOptions}
                                    fluid
                                    selection
                                    onChange={(_, data) =>
                                        setLocation(data.value as string)
                                    }
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                            className={"contacts__text"}
                            mobile={16}
                            computer={16}
                        >
                            How can we help?
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={11}>
                            <TextArea
                                rows={8}
                                onChange={event => setHelp(event.target.value)}
                            />
                        </Grid.Column>
                        <Grid.Column
                            mobile={16}
                            computer={16}
                            textAlign={"right"}
                            verticalAlign={"middle"}
                        >
                            <Button
                                onClick={contact}
                                className={"contacts__button"}
                                type={"submit"}
                            >
                                Contact
                            </Button>
                            <Button className={"contacts__button"}>
                                <a
                                    className={"contacts__link"}
                                    href={
                                        "https://calendly.com/shakespearemusic/sales"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Schedule a call
                                </a>
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Form>
            </div>
        </div>
    );
}

export default ContactsView;
