import "./PageTitleSecondary.scss";

interface PageTitleProps {
    title: string;
    secondary?: string | undefined;
}

function PageTitle({ title, secondary }: PageTitleProps): JSX.Element {
    return (
        <>
            <div className={"page-title-secondary__container"}>
                <div className={"page-title-secondary__text"}>{title}</div>
                {secondary && (
                    <div className={"page-title-secondary__text-secondary"}>
                        {secondary}
                    </div>
                )}
            </div>
        </>
    );
}

export default PageTitle;
