import "./LocationsView.scss";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Dropdown, Form, Grid, Input, Modal } from "semantic-ui-react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
    LocationSize,
    VenueLocation,
} from "../../../api/payments/types/VenueLocation";
import { EssentialPlanContext } from "../EssentialPlanView/EssentialPlanView";
import { Country } from "../../../api/countries/types/Country";
import { GetCountries } from "../../../api/countries/countries-service";
import { AddSelfServiceUser } from "../../../api/users/users-service";
import {
    AddSelfServiceUserRequest,
    UserType,
} from "../../../api/users/types/AddSelfServiceUserRequest";
import { Routes } from "../../../routes/Routes";
import Sprite from "../../../assets/icons/Sprite";
import BackButton from "../../../components/BackButton";
import PrimaryButton from "../../../components/ArrowButton";
import PageTitleSecondary from "../../../components/PageTitleSecondary";
import { useTranslation } from "react-i18next";

export interface LocationFormData {
    planType: string;
    locations: VenueLocation[];
}

export enum PaymentPlan {
    Yearly = "Yearly",
    Monthly = "Monthly",
}

function LocationsView(): JSX.Element {
    const [open, setOpen] = useState<boolean>();
    const [subTotal, setSubTotal] = useState<number>(0);
    const {
        setActiveStepIndex,
        accountFormData,
        locationsFormData,
        setLocationsFormData,
    } = useContext(EssentialPlanContext);
    const [paymentPlan, setPaymentPlan] = useState<string>(
        locationsFormData?.planType ?? PaymentPlan.Monthly
    );
    const [locations, setLocations] = useState<VenueLocation[]>(
        locationsFormData?.locations ?? [
            {
                name: undefined,
                country: undefined,
                town: undefined,
                postCode: undefined,
                size: undefined,
            },
        ]
    );

    const history = useHistory();

    const { data: countries, isLoading: isCountriesLoading } = useQuery<
        Country[]
    >("Countries", async () => await GetCountries(undefined), {
        staleTime: Infinity,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    const countryOptions = useMemo(() => {
        return countries
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(item => ({
                key: item.id,
                text: item.name,
                value: item.id,
            }));
    }, [countries]);

    const { t } = useTranslation();

    const plansOptions = [
        {
            key: PaymentPlan.Monthly,
            text: t("payment_plan_monthly_title"),
            value: PaymentPlan.Monthly,
            price: t("payment_plan_monthly_price"),
        },
        {
            key: PaymentPlan.Yearly,
            text: t("payment_plan_yearly_title"),
            value: PaymentPlan.Yearly,
            price: t("payment_plan_yearly_price"),
        },
    ];

    const sizeOptions = [
        {
            key: LocationSize.Below50,
            text: t("location_size_below_50"),
            value: LocationSize.Below50,
        },
        {
            key: LocationSize.Above50,
            text: t("location_size_above_50"),
            value: LocationSize.Above50,
        },
    ];

    const addUserMutation = useMutation(AddSelfServiceUser);

    useEffect(() => {
        let subTotal = 0;
        switch (paymentPlan) {
            case PaymentPlan.Monthly:
                for (const location of locations) {
                    switch (location.size) {
                        case LocationSize.Below50:
                            subTotal = subTotal + 19.99;
                            break;
                        case LocationSize.Above50:
                            subTotal = subTotal + 29.99;
                            break;
                    }
                }
                break;
            case PaymentPlan.Yearly:
                for (const location of locations) {
                    switch (location.size) {
                        case LocationSize.Below50:
                            subTotal = subTotal + 119.88;
                            break;
                        case LocationSize.Above50:
                            subTotal = subTotal + 239.88;
                            break;
                    }
                }
                break;
        }

        setSubTotal(subTotal);
    }, [locations, paymentPlan]);

    const onBack = (): void => {
        const formData: LocationFormData = {
            planType: paymentPlan,
            locations:
                paymentPlan === PaymentPlan.Monthly ||
                paymentPlan === PaymentPlan.Yearly
                    ? locations
                    : [],
        };
        setLocationsFormData(formData);
        setActiveStepIndex(0);
    };

    const onSubmit = async (): Promise<void> => {
        if (!paymentPlan) {
            return;
        }

        const formData: LocationFormData = {
            planType: paymentPlan,
            locations:
                paymentPlan === PaymentPlan.Monthly ||
                paymentPlan === PaymentPlan.Yearly
                    ? locations
                    : [],
        };
        setLocationsFormData(formData);

        if (accountFormData) {
            const addUserRequest: AddSelfServiceUserRequest = {
                city: accountFormData?.city,
                companyCode: accountFormData?.companyCode,
                companyName: accountFormData?.companyName,
                email: accountFormData?.email,
                firstName: accountFormData?.firstName,
                countryId: accountFormData?.countryId,
                lastName: accountFormData?.lastName,
                password: accountFormData?.password,
                phoneNumber: accountFormData?.phoneNumber,
                postCode: accountFormData?.postcode,
                termsAndConditionsAgreed: accountFormData?.terms,
                vatNumber: accountFormData?.vatNumber,
                type: UserType.BackgroundMusic,
            };

            const response = await addUserMutation.mutateAsync(addUserRequest);

            history.push(Routes.BackgroundMusicPayment, {
                id: response.id,
                firstName: accountFormData.firstName,
                lastName: accountFormData.lastName,
                email: accountFormData.email,
                phoneNumber: accountFormData.phoneNumber,
                subTotal: subTotal,
                paymentPlan: paymentPlan,
                locations: locations,
                businessType: accountFormData.businessType,
            });
        }
    };

    return (
        <div className={"locations"}>
            <div className={"locations__layout"}>
                <div className={"locations__content"}>
                    <Form className={"locations__form"}>
                        <Grid className={"locations__form-grid"}>
                            <Grid.Row>
                                <Grid.Column>
                                    <div className={"locations__section-title"}>
                                        {t("locations_section_plan")}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column computer={8} mobile={16}>
                                    {plansOptions.map(item => (
                                        <button
                                            key={item.key}
                                            className={
                                                paymentPlan === item.value
                                                    ? "locations__plan-btn locations__plan-btn--selected"
                                                    : "locations__plan-btn"
                                            }
                                            onClick={() =>
                                                setPaymentPlan(item.value)
                                            }
                                        >
                                            <Sprite
                                                name={
                                                    paymentPlan === item.value
                                                        ? "circleFilled"
                                                        : "circleEmpty"
                                                }
                                            />
                                            <span>
                                                <b>{item.text}</b> {item.price}
                                            </span>
                                        </button>
                                    ))}
                                </Grid.Column>
                                <Grid.Column
                                    computer={8}
                                    mobile={16}
                                ></Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid className={"locations__form-grid"}>
                            <Grid.Row>
                                <Grid.Column>
                                    <div className={"locations__section-title"}>
                                        {t(
                                            "locations_section_location_details"
                                        )}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            {locations.map((item, index) => (
                                <Grid.Row columns={5} key={index}>
                                    <Grid.Column computer={3} mobile={16}>
                                        <Form.Field
                                            control={Input}
                                            placeholder={t(
                                                "input_placeholder_location_name"
                                            )}
                                            value={locations[index].name}
                                            onChange={(event, data) =>
                                                setLocations(prevState => {
                                                    const newItems = [
                                                        ...prevState,
                                                    ];
                                                    newItems[index].name =
                                                        data.value;
                                                    return newItems;
                                                })
                                            }
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={3} mobile={16}>
                                        <Form.Field control={Input}>
                                            <Dropdown
                                                placeholder={t(
                                                    "input_placeholder_country"
                                                )}
                                                options={countryOptions}
                                                fluid
                                                selection
                                                value={locations[index].country}
                                                onChange={(event, data) => {
                                                    setLocations(prevState => {
                                                        const newItems = [
                                                            ...prevState,
                                                        ];
                                                        newItems[
                                                            index
                                                        ].country = data.value as string;
                                                        return newItems;
                                                    });
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer={3} mobile={16}>
                                        <Form.Field
                                            control={Input}
                                            placeholder={t(
                                                "input_placeholder_city"
                                            )}
                                            value={locations[index].town}
                                            onChange={(event, data) =>
                                                setLocations(prevState => {
                                                    const newItems = [
                                                        ...prevState,
                                                    ];
                                                    newItems[index].town =
                                                        data.value;
                                                    return newItems;
                                                })
                                            }
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={3} mobile={16}>
                                        <Form.Field
                                            control={Input}
                                            placeholder={t(
                                                "input_placeholder_postcode"
                                            )}
                                            value={locations[index].postCode}
                                            onChange={(event, data) =>
                                                setLocations(prevState => {
                                                    const newItems = [
                                                        ...prevState,
                                                    ];
                                                    newItems[index].postCode =
                                                        data.value;
                                                    return newItems;
                                                })
                                            }
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={3} mobile={16}>
                                        <Form.Field>
                                            <Dropdown
                                                placeholder={t(
                                                    "input_placeholder_size"
                                                )}
                                                options={sizeOptions}
                                                fluid
                                                selection
                                                value={locations[index].size}
                                                onChange={(event, data) => {
                                                    setLocations(prevState => {
                                                        const newItems = [
                                                            ...prevState,
                                                        ];
                                                        newItems[index].size =
                                                            data.value as LocationSize;
                                                        return newItems;
                                                    });
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column
                                        computer={1}
                                        className={"locations__remove-column"}
                                    >
                                        {index !== 0 && (
                                            <Sprite
                                                name={"close"}
                                                className={
                                                    "locations__remove-btn"
                                                }
                                                onClick={() =>
                                                    setLocations(prevState => [
                                                        ...prevState.slice(
                                                            0,
                                                            index
                                                        ),
                                                        ...prevState.slice(
                                                            index + 1
                                                        ),
                                                    ])
                                                }
                                            />
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                            ))}
                            <button
                                className={"locations__add-more"}
                                onClick={() => {
                                    if (locations.length < 4) {
                                        setLocations(prevState => [
                                            ...prevState,
                                            {
                                                name: undefined,
                                                country: undefined,
                                                town: undefined,
                                                postCode: undefined,
                                                size: undefined,
                                            },
                                        ]);
                                    } else {
                                        setOpen(true);
                                    }
                                }}
                            >
                                {t("btn_add_location")}
                            </button>
                        </Grid>
                    </Form>
                </div>
                <div className={"locations__separator-vertical"} />
                <div className={"locations__conditions"}>
                    <div className={"locations__conditions-price"}>
                        <PageTitleSecondary
                            title={subTotal === 0 ? "€ 0.00" : "€ " + subTotal}
                        />
                    </div>
                    <div className={"locations__section-title"}>
                        {t("locations_section_terms")}
                    </div>
                    <div className={"locations__conditions-list"}>
                        <ul>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>{t("locations_terms_item_1")}</span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>{t("locations_terms_item_2")}</span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>{t("locations_terms_item_3")}</span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>{t("locations_terms_item_4")}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"locations__separator-horizontal"} />
            <div className={"locations__actions"}>
                <BackButton onClick={() => onBack()} disabled={!paymentPlan}>
                    <Sprite name={"arrowBack"} />
                    <span>{t("btn_back")}</span>
                </BackButton>
                <PrimaryButton
                    onClick={() => onSubmit()}
                    disabled={!paymentPlan}
                >
                    <span>{t("btn_next")}</span>
                    <Sprite name={"arrow"} />
                </PrimaryButton>
            </div>
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size={"small"}
                className={"locations__modal"}
                closeIcon={
                    <Sprite
                        name={"close"}
                        className={"icon modals__close-icon"}
                    />
                }
            >
                <Modal.Content className={"locations__modal-content"}>
                    <div className={"locations__modal-title"}>
                        {t("locations_modal_title")}
                    </div>
                    <p>
                        For personalized offer press Contact of Schedule a call.
                    </p>
                    <p>You will not be charged yet!</p>
                    <div className={"locations__modal-buttons"}>
                        <PrimaryButton
                            onClick={() => history.push(Routes.Contacts)}
                        >
                            <span>Contact</span>
                            <Sprite name={"arrow"} />
                        </PrimaryButton>
                        <PrimaryButton onClick={() => onSubmit()} wide>
                            <a
                                href={
                                    "https://calendly.com/shakespearemusic/sales"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                Schedule a call
                            </a>
                            <Sprite name={"arrow"} />
                        </PrimaryButton>
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    );
}

export default LocationsView;
