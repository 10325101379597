import "./TrackPlansView.scss";
import { Grid } from "semantic-ui-react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Sprite from "../../../assets/icons/Sprite";
import PrimaryButton from "../../../components/ArrowButton";
import { Routes } from "../../../routes/Routes";
import { useTranslation } from "react-i18next";
import { LanguageParams } from "../../BackgroundMusic/PlansView/PlansView";

function TrackPlansView(): JSX.Element {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { lang } = useParams<LanguageParams>();

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    return (
        <div className={"track-plans"}>
            <div className={"track-plans__title"}>{t("plans_title")}</div>
            <Grid columns={3} centered className={"track-plans__content"}>
                <Grid.Column
                    computer={6}
                    mobile={12}
                    className={"track-plans__card"}
                >
                    <div className={"track-plans__item"}>
                        <div className={"track-plans__item-title"}>
                            {t("plan_commercial")}
                        </div>
                        <div className={"track-plans__item-list"}>
                            <ul>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_commercial_info_1")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_commercial_info_2")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_commercial_info_3")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_commercial_info_4")}</span>
                                </li>
                            </ul>
                        </div>
                        <div className={"track-plans__item-price-container"}>
                            <div className={"track-plans__item-price"}>
                                {t("plan_commercial_price_monthly")}
                            </div>
                            <div className={"track-plans__item-price"}>
                                {t("plan_commercial_price_yearly")}
                            </div>
                            <div className={"track-plans__item-price-addition"}>
                                {t("plan_commercial_price_info")}
                            </div>
                        </div>
                        <div
                            className={
                                "track-plans__actions plans__actions--center"
                            }
                        >
                            <PrimaryButton
                                onClick={() => history.push(Routes.Commercial)}
                            >
                                <span>{t("plan_commercial_btn")}</span>
                                <Sprite name={"arrow"} />
                            </PrimaryButton>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column
                    computer={6}
                    mobile={12}
                    className={"track-plans__card"}
                >
                    <div className={"track-plans__item"}>
                        <div className={"track-plans__item-title"}>
                            {t("plan_customized")}
                        </div>
                        <div className={"track-plans__item-list"}>
                            <ul>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_customized_info_1")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_customized_info_2")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_customized_info_3")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_customized_info_4")}</span>
                                </li>
                            </ul>
                        </div>
                        <div className={"track-plans__item-quote"}>
                            {t("plan_customized_quote")}
                        </div>
                    </div>
                    <div className={"track-plans__actions"}>
                        <PrimaryButton
                            onClick={() => history.push(Routes.Contacts)}
                        >
                            <span>{t("plan_customized_btn_sales")}</span>
                            <Sprite name={"arrowNew"} />
                        </PrimaryButton>
                        <PrimaryButton>
                            <a
                                className={"track-plans__link"}
                                href={
                                    "https://calendly.com/shakespearemusic/sales"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>{t("plan_customized_btn_contact")}</span>
                            </a>
                            <Sprite name={"arrowNew"} />
                        </PrimaryButton>
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default TrackPlansView;
