import "./PaymentView.scss";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo, useState } from "react";
import {
    CardElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { Form } from "semantic-ui-react";
import { StripeCardElement } from "@stripe/stripe-js/types/stripe-js/elements";
import { useHistory, useLocation } from "react-router-dom";
import stripeLogo from "./../../../assets/stripe_logo_blurple_small.png";
import { PaymentPlan } from "../LocationsView/LocationsView";
import { NavStatusItem } from "../EssentialPlanView/EssentialPlanView";
import { VenueLocation } from "../../../api/payments/types/VenueLocation";
import { InitiateIntentResponse } from "../../../api/payments/types/InitiateIntentResponse";
import { InitiateIntent } from "../../../api/payments/payments-service";
import { SubscriptionType } from "../../../api/payments/types/SubscriptionType";
import PrimaryButton from "../../../components/ArrowButton";
import Sprite from "../../../assets/icons/Sprite";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../routes/Routes";

const stripePromise = loadStripe(
    "pk_test_51MeEaAEjZgsyyHxvCTLpto9FTi59yWXh76t4IRbhaoI6mwHcVYdekU8n50Ltfke5NxbE3dzhDrBGyPQJass5z4Rc00xIKCVwOJ"
);

export interface PaymentLocationState {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    subTotal: number;
    paymentPlan: PaymentPlan;
    locations: VenueLocation[];
    businessType: string;
}

function PaymentForm(): JSX.Element {
    const [error, setError] = useState<string>();
    const [processing, setProcessing] = useState<boolean>(false);
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    const locationState = location.state as PaymentLocationState;
    const handleSubmit = async (
        event: React.MouseEvent<HTMLButtonElement>
    ): Promise<void> => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setProcessing(true);
        const cardElement: StripeCardElement | null =
            elements.getElement(CardElement);

        if (cardElement) {
            const { error: confirmError, paymentMethod } =
                await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    billing_details: {
                        name: `${locationState.firstName} ${locationState.lastName}`,
                        email: locationState.email,
                        phone: locationState.phoneNumber,
                    },
                });

            if (confirmError) {
                setError(confirmError.message);
                setProcessing(false);
                return;
            }

            const intent: InitiateIntentResponse = await InitiateIntent({
                type:
                    locationState.paymentPlan === PaymentPlan.Monthly
                        ? SubscriptionType.Monthly
                        : SubscriptionType.Yearly,
                userId: locationState.id,
                email: locationState.email,
                locations: locationState.locations,
                businessType: locationState.businessType,
            });

            // stripe.confirm
            const { error: paymentError } = await stripe.confirmCardPayment(
                intent.clientSecret,
                {
                    payment_method: paymentMethod?.id,
                    receipt_email: locationState.email,
                    setup_future_usage: "off_session",
                }
            );

            if (paymentError) {
                setError(paymentError.message);
                setProcessing(false);
            } else {
                setError(undefined);
                setProcessing(false);
                history.push(Routes.PaymentSuccess, {
                    showLocation: true,
                });
            }
        } else {
            setProcessing(false);
            setError(t("error_payments_other"));
        }
    };

    const navStatusItems: NavStatusItem[] = useMemo(() => {
        return [
            {
                title: t("nav_create_account_title"),
                subtitle: t("nav_create_account_subtitle"),
            },
            {
                title: t("nav_locations_title"),
                subtitle: t("nav_locations_subtitle"),
            },
            {
                title: t("nav_payment_title"),
                subtitle: "",
            },
        ];
    }, []);

    return (
        <div className={"payment"}>
            <div className={"essential-plan__nav"}>
                <div className={"essential-plan__nav-title"}>
                    {navStatusItems[2].title}
                </div>
                <div className={"essential-plan__nav-subtitle"}>
                    {navStatusItems[2].subtitle}
                </div>
                <div className={"essential-plan__nav-status"}>
                    <div className={"essential-plan__nav-status-indicator"}>
                        <Sprite name={"circleCompleted"} />
                        <div
                            className={"essential-plan__nav-status-separator"}
                        ></div>
                        <Sprite name={"circleCompleted"} />
                        <div
                            className={"essential-plan__nav-status-separator"}
                        ></div>
                        <Sprite name={"circleFilled"} />
                    </div>
                    <div className={"essential-plan__nav-status-text"}>
                        <span>{navStatusItems[0].title}</span>
                        <span>{navStatusItems[1].title}</span>
                        <span>{navStatusItems[2].title}</span>
                    </div>
                </div>
            </div>
            <div className={"payment__content"}>
                <div className={"payment__card"}>
                    <Form className={"payment__form"}>
                        <div className={"payment__provider"}>
                            <img
                                src={stripeLogo}
                                className={"payment__logo"}
                                alt={"Stripe"}
                            />
                        </div>
                        <div className={"payment__form-title"}>
                            {t("payments_card_title")}
                        </div>
                        <CardElement
                            id={"card-element"}
                            options={{
                                style: {
                                    base: {
                                        color: "#ffffff",
                                        fontFamily: "Poppins, sans-serif",
                                        fontSmoothing: "antialiased",
                                        fontSize: "18px",
                                        "::placeholder": {
                                            color: "#ffffff",
                                        },
                                        lineHeight: "40px",
                                        iconColor: "#ffffff",
                                    },
                                    invalid: {
                                        fontFamily: "Arial, sans-serif",
                                        color: "#fa755a",
                                        iconColor: "#fa755a",
                                    },
                                },
                            }}
                        />
                        {error && <div className="payment__error">{error}</div>}
                    </Form>
                </div>

                <div className={"payment__pricing"}>
                    <div className={"payment__pricing-title"}>
                        <span>
                            {locationState.paymentPlan} {t("payments_plan")}
                        </span>
                        <span>
                            {`€ ${locationState.subTotal}/`}
                            {`${
                                locationState.paymentPlan ===
                                PaymentPlan.Monthly
                                    ? t("payments_month")
                                    : t("payments_year")
                            }`}
                        </span>
                    </div>
                    <ul className={"payment__pricing-list"}>
                        <li>
                            {t("payments_pricing_start_date")}{" "}
                            {new Date().toLocaleDateString()}
                        </li>
                        <li>{t("payments_pricing_taxes")}</li>
                    </ul>
                </div>
            </div>
            <div className={"payment__separator-horizontal"} />
            <div className={"payment__actions"}>
                <PrimaryButton
                    onClick={async event => await handleSubmit(event)}
                    disabled={!stripe || processing}
                >
                    <span>
                        {!processing ? t("btn_pay") : t("btn_processing")}
                    </span>
                    {!processing && <Sprite name={"arrow"} />}
                </PrimaryButton>
            </div>
        </div>
    );
}

function PaymentView(): JSX.Element {
    const location = useLocation();
    const locationState = location.state as PaymentLocationState;

    return (
        <Elements stripe={stripePromise}>
            <PaymentForm />
        </Elements>
    );
}

export default PaymentView;
