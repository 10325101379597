import React from "react";
import "./styles/index.scss";
import MainLayout from "./layouts/MainLayout";
import "moment/locale/lt";
import "react-toastify/dist/ReactToastify.css";
import { Route } from "react-router-dom";
import { Routes } from "./routes/Routes";
import ContactsView from "./views/ContactsView";
import ThankYouView from "./views/ThankYouView";
import QuoteView from "./views/QuoteView";
import PlansView from "./views/BackgroundMusic/PlansView";
import EssentialPlanView from "./views/BackgroundMusic/EssentialPlanView";
import PaymentView from "./views/BackgroundMusic/PaymentView";
import PaymentSuccessView from "./views/BackgroundMusic/PaymentSuccessView";
import TrackPlansView from "./views/Tracks/TrackPlansView";
import CommercialPlanView from "./views/Tracks/CommercialPlanView/CommercialPlanView";
import TrackPaymentView from "./views/Tracks/TrackPaymentView";

function App(): JSX.Element {
    return (
        <MainLayout>
            <Route
                path={Routes.BackgroundMusicPlans}
                component={PlansView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.TrackPlans}
                component={TrackPlansView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.Contacts}
                component={ContactsView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.Quote}
                component={QuoteView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.Thanks}
                component={ThankYouView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.Essential}
                component={EssentialPlanView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.Commercial}
                component={CommercialPlanView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.BackgroundMusicPayment}
                component={PaymentView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.TrackPayment}
                component={TrackPaymentView}
                strict={true}
                exact={true}
            />
            <Route
                path={Routes.PaymentSuccess}
                component={PaymentSuccessView}
                strict={true}
                exact={true}
            />
        </MainLayout>
    );
}

export default App;
