import { GetConfiguration } from "../configuration/configuration-services";
import { Country } from "./types/Country";
import endpoints from "../utils/endpoints";
import apiHeaders from "../utils/api-headers";
import { GetAxiosInstance } from "../utils/api-clients";

export async function GetCountries(
    signal: AbortSignal | undefined
): Promise<Country[]> {
    const configuration = await GetConfiguration();
    const url = `${configuration.metadataApiUrl}/${endpoints.Countries}`;

    const httpClient = await GetAxiosInstance(apiHeaders.JsonContent);
    return await httpClient
        .get<Country[]>(url, { signal })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
