import "./PageTitle.scss";

interface PageTitleProps {
    title: string;
}

function PageTitle({ title }: PageTitleProps): JSX.Element {
    return (
        <>
            <div className={"page-title__container"}>
                <div className={"page-title__text"}>{title}</div>
            </div>
        </>
    );
}

export default PageTitle;
