import { ReactNode } from "react";
import "./MainLayout.scss";
import TopBarContainer from "../../containers/TopBarContainer";

interface MainLayoutProps {
    children?: ReactNode;
}

function MainLayout({ children }: MainLayoutProps): JSX.Element {
    return (
        <div className={"main-layout"}>
            <div className={"main-layout__container"}>
                <div className={"main-layout__content"}>{children}</div>
            </div>
        </div>
    );
}

export default MainLayout;
