import "./ThankYouView.scss";

function ThankYouView(): JSX.Element {
    return (
        <div className={"thank-you"}>
            Thank you! We will get in touch with you soon.
        </div>
    );
}

export default ThankYouView;
