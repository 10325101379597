import { Configuration } from "./types/Configuration";
import { GetAxiosInstance } from "../utils/api-clients";
import apiHeaders from "../utils/api-headers";
import endpoints from "../utils/endpoints";

export async function GetConfiguration(): Promise<Configuration> {
    const axiosInstance = await GetAxiosInstance(apiHeaders.JsonContent);

    return await axiosInstance
        .get<Configuration>(endpoints.Configuration)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
