export const LOCAL_API_PREFIX =
    "https://sm-web-self-service-qa-we.azurewebsites.net";

export default {
    Contact: "email/contact",
    Quote: "email/quote",
    Configuration: "/api/configuration",
    Countries: "countries",
    Users: "users",
    Payments: "payments",
};
