import { DropdownItemProps } from "semantic-ui-react";
import { useMemo } from "react";

function useSubscriptionItems(): DropdownItemProps[] {
    return useMemo(() => {
        return [
            {
                key: "monthly",
                text: "Monthly",
                value: "Monthly",
            },
            {
                key: "yearly",
                text: "Yearly",
                value: "Yearly",
            },
        ];
    }, []);
}

export { useSubscriptionItems as default };
