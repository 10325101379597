export const Routes = {
    BackgroundMusicPlans: "/:lang/background-music",
    TrackPlans: "/:lang/tracks",
    Commercial: "/tracks/account",
    TrackPayment: "/tracks/payment",
    Contacts: "/contact",
    Quote: "/quote",
    Thanks: "/thanks",
    Locations: "/background-music/locations",
    Essential: "/background-music/account",
    BackgroundMusicPayment: "/background-music/payment",
    PaymentSuccess: "/payment-success",
};
