export interface AddSelfServiceUserRequest {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    countryId: string;
    phoneNumber: string;
    companyName: string;
    companyCode: string;
    city: string;
    vatNumber: string;
    postCode: string;
    termsAndConditionsAgreed: boolean;
    type: UserType;
}

export enum UserType {
    BackgroundMusic = "BackgroundMusic",
    Tracks = "Tracks",
}
