import "./PlansView.scss";
import { Grid } from "semantic-ui-react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Sprite from "../../../assets/icons/Sprite";
import PrimaryButton from "../../../components/ArrowButton";
import { Routes } from "../../../routes/Routes";
import { useTranslation } from "react-i18next";

export interface LanguageParams {
    lang: string;
}

function PlansView(): JSX.Element {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { lang } = useParams<LanguageParams>();

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    return (
        <div className={"plans"}>
            <div className={"plans__title"}>{t("plans_title")}</div>
            <Grid columns={3} centered className={"plans__content"}>
                <Grid.Column computer={6} mobile={12} className={"plans__card"}>
                    <div className={"plans__item"}>
                        <div className={"plans__item-title"}>
                            {t("plan_essential")}
                        </div>
                        <div className={"plans__item-list"}>
                            <ul>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_1")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_2")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_3")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_4")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_5")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_6")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_7")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_8")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_essential_info_9")}</span>
                                </li>
                            </ul>
                        </div>
                        <div className={"plans__item-price-container"}>
                            <div className={"plans__item-price"}>
                                {t("plan_essential_price_monthly")}
                            </div>
                            <div className={"plans__item-price"}>
                                {t("plan_essential_price_yearly")}
                            </div>
                            <div className={"plans__item-price-addition"}>
                                {t("plan_essential_price_info")}
                            </div>
                        </div>
                        <div
                            className={"plans__actions plans__actions--center"}
                        >
                            <PrimaryButton
                                onClick={() => history.push(Routes.Essential)}
                            >
                                <span>{t("plan_essential_btn")}</span>
                                <Sprite name={"arrow"} />
                            </PrimaryButton>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column computer={6} mobile={12} className={"plans__card"}>
                    <div className={"plans__item"}>
                        <div className={"plans__item-title"}>
                            {t("plan_exclusive")}
                        </div>
                        <div className={"plans__item-list"}>
                            <ul>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_exclusive_info_1")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_exclusive_info_2")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_exclusive_info_3")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_exclusive_info_4")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_exclusive_info_5")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_exclusive_info_6")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_exclusive_info_7")}</span>
                                </li>
                                <li>
                                    <Sprite name={"checkmark"} />
                                    <span>{t("plan_exclusive_info_8")}</span>
                                </li>
                            </ul>
                        </div>
                        <div className={"plans__item-quote"}>
                            {t("plan_exclusive_quote")}
                        </div>
                    </div>
                    <div className={"plans__actions"}>
                        <PrimaryButton
                            onClick={() => history.push(Routes.Contacts)}
                        >
                            <span>{t("plan_exclusive_btn_sales")}</span>
                            <Sprite name={"arrowNew"} />
                        </PrimaryButton>
                        <PrimaryButton>
                            <a
                                className={"plans__link"}
                                href={
                                    "https://calendly.com/shakespearemusic/sales"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>{t("plan_exclusive_btn_contact")}</span>
                            </a>
                            <Sprite name={"arrowNew"} />
                        </PrimaryButton>
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default PlansView;
