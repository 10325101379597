import { GetConfiguration } from "../configuration/configuration-services";
import endpoints from "../utils/endpoints";
import { AddSelfServiceUserRequest } from "./types/AddSelfServiceUserRequest";
import { GetAxiosInstance } from "../utils/api-clients";
import apiHeaders from "../utils/api-headers";
import { AddSelfServiceUserResponse } from "./types/AddSelfServiceUserResponse";

export async function AddSelfServiceUser(
    request: AddSelfServiceUserRequest
): Promise<AddSelfServiceUserResponse> {
    const configuration = await GetConfiguration();
    const url = `${configuration.metadataApiUrl}/${endpoints.Users}/self-service`;

    const httpClient = await GetAxiosInstance(apiHeaders.JsonContent);
    return await httpClient
        .post(url, request)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
