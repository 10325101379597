import "./CreateAccountView.scss";
import { Dropdown, Form, Grid, Checkbox, Input } from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";
import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import { EssentialPlanContext } from "../EssentialPlanView/EssentialPlanView";
import { Country } from "../../../api/countries/types/Country";
import { GetCountries } from "../../../api/countries/countries-service";
import useLocationItems from "../../../hooks/DropdownItems/LocationItems";
import Sprite from "../../../assets/icons/Sprite";
import PrimaryButton from "../../../components/ArrowButton";
import { useTranslation } from "react-i18next";

export interface AccountFormData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    companyName: string;
    countryId: string;
    businessType: string;
    companyCode: string;
    city: string;
    vatNumber: string;
    postcode: string;
    terms: boolean;
}

const initialFormValues: AccountFormData = {
    businessType: "",
    city: "",
    companyCode: "",
    companyName: "",
    confirmPassword: "",
    countryId: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    postcode: "",
    vatNumber: "",
    terms: false,
};

export interface ValidationError {
    type: string;
    message: string;
}

function CreateAccountView(): JSX.Element {
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
        []
    );

    const { setActiveStepIndex, accountFormData, setAccountFormData } =
        useContext(EssentialPlanContext);

    const { data: countries, isLoading: isCountriesLoading } = useQuery<
        Country[]
    >("Countries", async () => await GetCountries(undefined), {
        staleTime: Infinity,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    const countryOptions = useMemo(() => {
        return countries
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(item => ({
                key: item.id,
                text: item.name,
                value: item.id,
            }));
    }, [countries]);

    const locationOptions = useLocationItems();

    const { t } = useTranslation();

    const validateForm = (values: AccountFormData): boolean => {
        const errors = new Array<ValidationError>();

        if (!values.firstName) {
            errors.push({
                type: "firstName",
                message: t("input_validation_first_name"),
            });
        }

        if (!values.lastName) {
            errors.push({
                type: "firstName",
                message: t("input_validation_last_name"),
            });
        }

        const emailRegex = new RegExp(
            /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
            "gm"
        );
        if (!values.email || !emailRegex.test(values.email)) {
            errors.push({
                type: "email",
                message: t("input_validation_email"),
            });
        }

        const passwordRegExp = new RegExp(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
        );
        if (!values.password || !passwordRegExp.test(values.password)) {
            errors.push({
                type: "password",
                message: t("input_validation_password"),
            });
        }

        if (
            !values.confirmPassword ||
            values.password !== values.confirmPassword
        ) {
            errors.push({
                type: "confirmPassword",
                message: t("input_validation_confirm_password"),
            });
        }

        if (!values.companyName) {
            errors.push({
                type: "companyName",
                message: t("input_validation_company_name"),
            });
        }

        if (!values.countryId) {
            errors.push({
                type: "countryId",
                message: t("input_validation_country"),
            });
        }

        if (!values.businessType) {
            errors.push({
                type: "businessType",
                message: t("input_validation_business_type"),
            });
        }

        if (!values.terms) {
            errors.push({
                type: "terms",
                message: t("input_validation_terms"),
            });
        }

        setValidationErrors(errors);
        return errors.length === 0;
    };

    const onSubmit = (values: AccountFormData): void => {
        const formValid = validateForm(values);
        if (!formValid) {
            return;
        }

        setAccountFormData(values);
        setActiveStepIndex(1);
    };

    const formik = useFormik({
        initialValues: accountFormData ?? initialFormValues,
        onSubmit: onSubmit,
        enableReinitialize: true,
    });

    return (
        <div className={"create-account"}>
            <div className={"create-account__layout"}>
                <div className={"create-account__content"}>
                    <Form className={"create-account__form"}>
                        <Grid
                            columns={1}
                            className={"create-account__form-grid"}
                            stackable
                        >
                            <Grid.Column mobile={16} computer={16}>
                                <div
                                    className={"create-account__section-title"}
                                >
                                    <span>
                                        {t("create_account_section_account")}
                                    </span>
                                </div>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={8}>
                                <Form.Field
                                    control={Input}
                                    required
                                    id="form-input-control-first-name"
                                    placeholder={t(
                                        "input_placeholder_first_name"
                                    )}
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    name={"firstName"}
                                    error={
                                        validationErrors.find(
                                            err => err.type === "firstName"
                                        )?.message
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={8}>
                                <Form.Field
                                    control={Input}
                                    required
                                    id="form-input-control-last-name"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    name={"lastName"}
                                    error={
                                        validationErrors.find(
                                            err => err.type === "lastName"
                                        )?.message
                                    }
                                    placeholder={t(
                                        "input_placeholder_last_name"
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={8}>
                                <Form.Field
                                    type={"email"}
                                    control={Input}
                                    required
                                    id="form-input-control-email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    name={"email"}
                                    error={
                                        validationErrors.find(
                                            err => err.type === "email"
                                        )?.message
                                    }
                                    placeholder={t("input_placeholder_email")}
                                />
                            </Grid.Column>
                            <Grid.Column required mobile={16} computer={8}>
                                <Form.Field
                                    control={Input}
                                    type={"password"}
                                    required
                                    id="form-input-control-password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    name={"password"}
                                    error={
                                        validationErrors.find(
                                            err => err.type === "password"
                                        )?.message
                                    }
                                    placeholder={t(
                                        "input_placeholder_password"
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={8}>
                                <PhoneInput
                                    value={formik.values.phoneNumber}
                                    country="gb"
                                    onChange={value =>
                                        formik.setFieldValue(
                                            "phoneNumber",
                                            value
                                        )
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={8}>
                                <Form.Field
                                    control={Input}
                                    type={"password"}
                                    required
                                    id="form-input-control-confirm-password"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    name={"confirmPassword"}
                                    error={
                                        validationErrors.find(
                                            err =>
                                                err.type === "confirmPassword"
                                        )?.message
                                    }
                                    placeholder={t(
                                        "input_placeholder_confirm_password"
                                    )}
                                />
                                <div className={"create-account__password"}>
                                    <Sprite name={"alert"} />
                                    <div>
                                        <div>
                                            {t(
                                                "input_validation_validation_msg_1"
                                            )}
                                        </div>
                                        <div>
                                            {t(
                                                "input_validation_validation_msg_2"
                                            )}
                                        </div>
                                        <div>
                                            {t(
                                                "input_validation_validation_msg_3"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={16}>
                                <div
                                    className={"create-account__section-title"}
                                >
                                    <span>
                                        {t("create_account_section_company")}
                                    </span>
                                    <Sprite name={"question"} />
                                </div>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={5}>
                                <Form.Field
                                    control={Input}
                                    required
                                    id="form-input-control-company-name"
                                    value={formik.values.companyName}
                                    onChange={formik.handleChange}
                                    name={"companyName"}
                                    error={
                                        validationErrors.find(
                                            err => err.type === "companyName"
                                        )?.message
                                    }
                                    placeholder={t(
                                        "input_placeholder_company_name"
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={5}>
                                <Form.Field
                                    required
                                    id="form-input-control-country"
                                    name={"countryId"}
                                    error={
                                        validationErrors.find(
                                            err => err.type === "countryId"
                                        )?.message
                                    }
                                >
                                    <Dropdown
                                        placeholder={t(
                                            "input_placeholder_country"
                                        )}
                                        options={countryOptions}
                                        fluid
                                        selection
                                        search
                                        value={formik.values.countryId}
                                        onChange={(event, data) =>
                                            formik.setFieldValue(
                                                "countryId",
                                                data.value as string
                                            )
                                        }
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={6}>
                                <Form.Field
                                    required
                                    id="form-input-business-type"
                                    name={"businessType"}
                                    error={
                                        validationErrors.find(
                                            err => err.type === "businessType"
                                        )?.message
                                    }
                                >
                                    <Dropdown
                                        placeholder={t(
                                            "input_placeholder_business_type"
                                        )}
                                        fluid
                                        selection
                                        options={locationOptions}
                                        value={formik.values.businessType}
                                        onChange={(event, data) => {
                                            formik.setFieldValue(
                                                "businessType",
                                                data.value
                                            );
                                        }}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Row>
                                <Grid.Column mobile={16} computer={5}>
                                    <Form.Field
                                        control={Input}
                                        id="form-input-control-company-code"
                                        value={formik.values.companyCode}
                                        onChange={formik.handleChange}
                                        name={"companyCode"}
                                        placeholder={t(
                                            "input_placeholder_company_code"
                                        )}
                                    />
                                </Grid.Column>
                                <Grid.Column
                                    className={"create-account__grid-column"}
                                    mobile={16}
                                    computer={5}
                                >
                                    <Form.Field
                                        control={Input}
                                        id="form-input-control-city"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        name={"city"}
                                        placeholder={t(
                                            "input_placeholder_city"
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column mobile={16} computer={5}>
                                    <Form.Field
                                        control={Input}
                                        id="form-input-control-vat-number"
                                        value={formik.values.vatNumber}
                                        onChange={formik.handleChange}
                                        name={"vatNumber"}
                                        placeholder={t(
                                            "input_placeholder_vat_number"
                                        )}
                                    />
                                </Grid.Column>
                                <Grid.Column
                                    className={"create-account__grid-column"}
                                    mobile={16}
                                    computer={5}
                                >
                                    <Form.Field
                                        control={Input}
                                        id="form-input-control-postcode"
                                        value={formik.values.postcode}
                                        onChange={formik.handleChange}
                                        name={"postcode"}
                                        placeholder={t(
                                            "input_placeholder_postcode"
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Column mobile={8} computer={8}>
                                <div className={"create-account__terms"}>
                                    <Checkbox
                                        checked={formik.values.terms}
                                        onChange={(event, data) =>
                                            formik.setFieldValue(
                                                "terms",
                                                data.checked
                                            )
                                        }
                                    />
                                    <a
                                        className={"create-account__terms-icon"}
                                        href={
                                            "https://www.shakespearemusic.com/privacy-policy"
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t("input_placeholder_terms")}
                                    </a>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </div>
                <div className={"create-account__separator-vertical"} />
                <div className={"create-account__features"}>
                    <div className={"create-account__section-title"}>
                        {t("create_account_features_title")}
                    </div>
                    <div className={"create-account__features-list"}>
                        <ul>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_1")}
                                </span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_2")}
                                </span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_3")}
                                </span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_4")}
                                </span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_5")}
                                </span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_6")}
                                </span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_7")}
                                </span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_8")}
                                </span>
                            </li>
                            <li>
                                <Sprite name={"checkmark"} />
                                <span>
                                    {t("create_account_features_item_9")}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className={"create-account__features-pricing"}>
                        {t("create_account_pricing_monthly")} <br />{" "}
                        {t("create_account_pricing_or")}
                        <br /> {t("create_account_pricing_yearly")}
                    </div>

                    <div className={"create-account__features-additional"}>
                        {t("create_account_pricing_info")}
                    </div>
                </div>
            </div>
            <div className={"create-account__separator-horizontal"} />
            <div className={"create-account__actions"}>
                <PrimaryButton
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.values.terms}
                >
                    <span>{t("btn_next")}</span>
                    <Sprite name={"arrow"} />
                </PrimaryButton>
            </div>
        </div>
    );
}

export default CreateAccountView;
