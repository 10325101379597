import { ReactNode } from "react";
import { Button } from "semantic-ui-react";
import "./BackButton.scss";

interface BackButtonProps {
    children: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
}

function BackButton({
    children,
    onClick,
    disabled,
}: BackButtonProps): JSX.Element {
    return (
        <Button className={"back-button"} onClick={onClick} disabled={disabled}>
            {children}
        </Button>
    );
}

export default BackButton;
