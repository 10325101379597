import { DropdownItemProps } from "semantic-ui-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function useLocationItems(): DropdownItemProps[] {
    const { t } = useTranslation();

    return useMemo(() => {
        return [
            {
                key: "restaurant",
                text: t("business_type_restaurant"),
                value: "Restaurant",
            },
            {
                key: "retail",
                text: t("business_type_retail"),
                value: "Retail",
            },
            {
                key: "cafe",
                text: t("business_type_cafe"),
                value: "CoffeeShop",
            },
            {
                key: "office",
                text: t("business_type_office"),
                value: "Office",
            },
            {
                key: "automotive",
                text: t("business_type_automotive"),
                value: "Automotive",
            },
            {
                key: "gym",
                text: t("business_type_gym"),
                value: "Gym",
            },
            {
                key: "hotel",
                text: t("business_type_hotel"),
                value: "Hotel",
            },
            {
                key: "healthcare",
                text: t("business_type_healthcare"),
                value: "Healthcare",
            },
            {
                key: "salonSpa",
                text: t("business_type_salonSpa"),
                value: "Salon",
            },
            {
                key: "customerServiceLocation",
                text: t("business_type_customerServiceLocation"),
                value: "CustomerServiceLocation",
            },
            {
                key: "event",
                text: t("business_type_event"),
                value: "Event",
            },
            {
                key: "fashionShow",
                text: t("business_type_fashionShow"),
                value: "FashionShow",
            },
            {
                key: "other",
                text: t("business_type_other"),
                value: "Other",
            },
        ];
    }, []);
}

export { useLocationItems as default };
