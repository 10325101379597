import { GetConfiguration } from "../configuration/configuration-services";
import endpoints from "../utils/endpoints";
import { GetAxiosInstance } from "../utils/api-clients";
import apiHeaders from "../utils/api-headers";
import { InitiateBackgroundMusicIntentRequest } from "./types/InitiateBackgroundMusicIntentRequest";
import { InitiateIntentResponse } from "./types/InitiateIntentResponse";
import { InitiateTracksIntentRequest } from "./types/InitiateTracksIntentRequest";

export async function InitiateIntent(
    request: InitiateBackgroundMusicIntentRequest
): Promise<InitiateIntentResponse> {
    const configuration = await GetConfiguration();
    const url = `${configuration.selfServiceApiUrl}/${endpoints.Payments}/initiate-intent/background-music`;

    const httpClient = await GetAxiosInstance(apiHeaders.JsonContent);
    return await httpClient
        .post(url, request)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export async function InitiateTracksIntent(
    request: InitiateTracksIntentRequest
): Promise<InitiateIntentResponse> {
    const configuration = await GetConfiguration();
    const url = `${configuration.selfServiceApiUrl}/${endpoints.Payments}/initiate-intent/tracks`;

    const httpClient = await GetAxiosInstance(apiHeaders.JsonContent);
    return await httpClient
        .post(url, request)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
