import * as React from "react";
import classNames from "classnames";

// Code to obtain original viewBox
// var clientrect = document.querySelector('svg path').getBBox();console.log(clientrect.x+' '+clientrect.y+' '+clientrect.width+' '+clientrect.height);

export const sprite = {
    info: (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.3 3.5H7.7V4.9H6.3V3.5ZM6.3 6.3H7.7V10.5H6.3V6.3ZM7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6Z"
                fill="#323232"
            />
        </svg>
    ),
    question: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            width="18"
            height="18"
            viewBox="0 0 512 512"
        >
            <title>Help Circle</title>
            <path
                d="M256 80a176 176 0 10176 176A176 176 0 00256 80z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="50"
            />
            <path
                d="M200 202.29s.84-17.5 19.57-32.57C230.68 160.77 244 158.18 256 158c10.93-.14 20.69 1.67 26.53 4.45 10 4.76 29.47 16.38 29.47 41.09 0 26-17 37.81-36.37 50.8S251 281.43 251 296"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="50"
            />
            <circle
                cx="250"
                cy="348"
                r="20"
                stroke="currentColor"
                fill="currentColor"
            />
        </svg>
    ),
    alert: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ionicon"
            width="20"
            height="20"
            viewBox="0 0 512 512"
        >
            <path
                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="32"
            />
            <path
                d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
            />
            <path d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z" />
        </svg>
    ),
    arrow: (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.00181 16.1333C7.00181 15.8595 7.10717 15.5969 7.29471 15.4033C7.48224 15.2098 7.7366 15.101 8.00181 15.101L21.5918 15.101L20.2918 13.7694C20.1035 13.575 19.9977 13.3114 19.9977 13.0365C19.9977 12.7616 20.1035 12.498 20.2918 12.3036C20.4801 12.1092 20.7355 12 21.0018 12C21.2681 12 21.5235 12.1092 21.7118 12.3036L24.7118 15.4004C24.8506 15.5455 24.9446 15.7298 24.9819 15.9301C25.0193 16.1303 24.9984 16.3375 24.9218 16.5255C24.8468 16.714 24.7194 16.8754 24.5557 16.9893C24.392 17.1032 24.1992 17.1645 24.0018 17.1655L8.00181 17.1655C7.7366 17.1655 7.48224 17.0568 7.29471 16.8632C7.10717 16.6696 7.00181 16.407 7.00181 16.1333Z"
                fill="white"
            />
            <path
                d="M19.9959 19.2309C19.9951 19.0951 20.0204 18.9604 20.0701 18.8346C20.1199 18.7089 20.1932 18.5945 20.2859 18.498L23.2859 15.4012C23.4742 15.2069 23.7296 15.0977 23.9959 15.0977C24.1278 15.0977 24.2583 15.1245 24.3802 15.1766C24.502 15.2286 24.6127 15.305 24.7059 15.4012C24.7991 15.4975 24.8731 15.6117 24.9236 15.7375C24.974 15.8632 25 15.998 25 16.1341C25 16.409 24.8942 16.6727 24.7059 16.867L21.7059 19.9638C21.6129 20.0606 21.5023 20.1374 21.3805 20.1898C21.2586 20.2422 21.1279 20.2692 20.9959 20.2692C20.8639 20.2692 20.7332 20.2422 20.6113 20.1898C20.4895 20.1374 20.3789 20.0606 20.2859 19.9638C20.1932 19.8674 20.1199 19.753 20.0701 19.6272C20.0204 19.5014 19.9951 19.3668 19.9959 19.2309Z"
                fill="white"
            />
        </svg>
    ),
    close: (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.57611 3.57611C3.90155 3.25067 4.42918 3.25067 4.75462 3.57611L9.9987 8.82019L15.2428 3.57611C15.5682 3.25067 16.0958 3.25067 16.4213 3.57611C16.7467 3.90155 16.7467 4.42918 16.4213 4.75462L11.1772 9.9987L16.4213 15.2428C16.7467 15.5682 16.7467 16.0958 16.4213 16.4213C16.0958 16.7467 15.5682 16.7467 15.2428 16.4213L9.9987 11.1772L4.75462 16.4213C4.42918 16.7467 3.90155 16.7467 3.57611 16.4213C3.25067 16.0958 3.25067 15.5682 3.57611 15.2428L8.82019 9.9987L3.57611 4.75462C3.25067 4.42918 3.25067 3.90155 3.57611 3.57611Z"
                fill="#ffffff"
            />
        </svg>
    ),
    arrowNew: (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path
                d="M7.71373 16.1333C7.71373 15.8595 7.81908 15.5969 8.00662 15.4033C8.19416 15.2098 8.44851 15.101 8.71373 15.101L22.3037 15.101L21.0037 13.7694C20.8154 13.575 20.7096 13.3114 20.7096 13.0365C20.7096 12.7616 20.8154 12.498 21.0037 12.3036C21.192 12.1092 21.4474 12 21.7137 12C21.98 12 22.2354 12.1092 22.4237 12.3036L25.4237 15.4004C25.5625 15.5455 25.6565 15.7298 25.6939 15.9301C25.7312 16.1303 25.7103 16.3375 25.6337 16.5255C25.5587 16.714 25.4313 16.8754 25.2676 16.9893C25.1039 17.1032 24.9112 17.1645 24.7137 17.1655L8.71373 17.1655C8.44851 17.1655 8.19416 17.0568 8.00662 16.8632C7.81908 16.6696 7.71373 16.407 7.71373 16.1333Z"
                fill="black"
            ></path>
            <path
                d="M20.7117 19.2309C20.711 19.0951 20.7362 18.9604 20.786 18.8346C20.8357 18.7089 20.909 18.5945 21.0017 18.498L24.0017 15.4012C24.19 15.2069 24.4454 15.0977 24.7117 15.0977C24.8436 15.0977 24.9742 15.1245 25.096 15.1766C25.2178 15.2286 25.3285 15.305 25.4217 15.4012C25.515 15.4975 25.5889 15.6117 25.6394 15.7375C25.6898 15.8632 25.7158 15.998 25.7158 16.1341C25.7158 16.409 25.61 16.6727 25.4217 16.867L22.4217 19.9638C22.3288 20.0606 22.2182 20.1374 22.0963 20.1898C21.9744 20.2422 21.8437 20.2692 21.7117 20.2692C21.5797 20.2692 21.449 20.2422 21.3272 20.1898C21.2053 20.1374 21.0947 20.0606 21.0017 19.9638C20.909 19.8674 20.8357 19.753 20.786 19.6272C20.7362 19.5014 20.711 19.3668 20.7117 19.2309Z"
                fill="black"
            ></path>
        </svg>
    ),
    checkmark: (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.5286 3.52863L6 11.0573L3.13802 8.19529L2.19531 9.138L6 12.9427L14.4714 4.47133L13.5286 3.52863Z"
                fill="#F3C348"
            />
        </svg>
    ),
    circleFilled: (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="8" r="7.5" fill="#353535" stroke="#F3C348" />
            <circle cx="8" cy="8" r="5" fill="#F3C348" />
        </svg>
    ),
    circle: (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="8" r="8" fill="#606060" />
        </svg>
    ),
    circleCompleted: (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="8" r="7.5" fill="white" stroke="white" />
        </svg>
    ),
    circleEmpty: (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="8" r="7.5" stroke="white" />
        </svg>
    ),
    remove: (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.55469 3.33203H13.4436L12.7903 13.1317C12.7202 14.1823 11.8476 14.9987 10.7947 14.9987H5.20357C4.15064 14.9987 3.27804 14.1823 3.208 13.1317L2.55469 3.33203Z"
                stroke="#494F5C"
                strokeWidth="1.5"
            />
            <path
                d="M5.66797 3.33333V1H10.3346V3.33333"
                stroke="#494F5C"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M1 3.33203H15"
                stroke="#494F5C"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M6.44531 6.44531V11.8898"
                stroke="#494F5C"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M9.55469 6.44531V11.8898"
                stroke="#494F5C"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    ),
    arrowBack: (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.9982 15.8667C24.9982 16.1405 24.8928 16.4031 24.7053 16.5967C24.5178 16.7902 24.2634 16.899 23.9982 16.899L10.4082 16.899L11.7082 18.2306C11.8965 18.425 12.0023 18.6886 12.0023 18.9635C12.0023 19.2384 11.8965 19.502 11.7082 19.6964C11.5199 19.8908 11.2645 20 10.9982 20C10.7319 20 10.4765 19.8908 10.2882 19.6964L7.28819 16.5996C7.14943 16.4545 7.05543 16.2702 7.01806 16.0699C6.98068 15.8697 7.00161 15.6625 7.07819 15.4745C7.15321 15.286 7.2806 15.1246 7.44432 15.0107C7.60804 14.8968 7.80076 14.8355 7.99819 14.8345L23.9982 14.8345C24.2634 14.8345 24.5178 14.9432 24.7053 15.1368C24.8928 15.3304 24.9982 15.593 24.9982 15.8667Z"
                fill="white"
            />
            <path
                d="M12.0041 12.7691C12.0049 12.9049 11.9796 13.0396 11.9299 13.1654C11.8801 13.2911 11.8068 13.4055 11.7141 13.502L8.71409 16.5988C8.52579 16.7931 8.27039 16.9023 8.00409 16.9023C7.87223 16.9023 7.74166 16.8755 7.61984 16.8234C7.49802 16.7714 7.38733 16.695 7.29409 16.5988C7.20085 16.5025 7.12689 16.3883 7.07643 16.2625C7.02597 16.1368 7 16.002 7 15.8659C7 15.591 7.10579 15.3273 7.29409 15.133L10.2941 12.0362C10.3871 11.9394 10.4977 11.8626 10.6195 11.8102C10.7414 11.7578 10.8721 11.7308 11.0041 11.7308C11.1361 11.7308 11.2668 11.7578 11.3887 11.8102C11.5105 11.8626 11.6211 11.9394 11.7141 12.0362C11.8068 12.1326 11.8801 12.247 11.9299 12.3728C11.9796 12.4986 12.0049 12.6332 12.0041 12.7691Z"
                fill="white"
            />
        </svg>
    ),
};

export type IconNames = keyof typeof sprite;

export interface IconSpriteProps {
    name: IconNames;
    className?: string;
    onClick?: (event: React.SyntheticEvent<Element>) => void;
    onMouseOver?: (event: React.SyntheticEvent<Element>) => void;
    onMouseOut?: (event: React.SyntheticEvent<Element>) => void;
    style?: React.CSSProperties;
}

const Sprite = ({
    name,
    className,
    onClick,
    onMouseOver,
    onMouseOut,
}: IconSpriteProps): JSX.Element | null => {
    return sprite[name] ? (
        <span
            className={classNames("a-icon", className)}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            aria-hidden="true"
        >
            {sprite[name]}
        </span>
    ) : null;
};

export default Sprite;
