import { Button } from "semantic-ui-react";
import "./PrimaryButton.scss";
import React, { ReactNode } from "react";

interface PrimaryButtonProps {
    children: ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    wide?: boolean;
}

function PrimaryButton({
    children,
    onClick,
    disabled,
    wide,
}: PrimaryButtonProps): JSX.Element {
    return (
        <Button
            className={
                !wide ? "primary-button" : "primary-button primary-button--wide"
            }
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Button>
    );
}

export default PrimaryButton;
