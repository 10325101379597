import "./EssentialPlanView.scss";
import React, { createContext, useContext, useMemo, useState } from "react";
import CreateAccountView from "../CreateAccountView";
import LocationsView from "../LocationsView";
import { AccountFormData } from "../CreateAccountView/CreateAccountView";
import { LocationFormData, PaymentPlan } from "../LocationsView/LocationsView";
import Sprite from "../../../assets/icons/Sprite";
import { useTranslation } from "react-i18next";

/**
 * @ignore
 */
export const stub = (): never => {
    throw new Error("You forgot to wrap your component in Provider.");
};

export interface EssentialPlanContext {
    activeStepIndex: number;
    setActiveStepIndex: React.Dispatch<React.SetStateAction<number>>;
    setAccountFormData: React.Dispatch<
        React.SetStateAction<AccountFormData | undefined>
    >;
    setLocationsFormData: React.Dispatch<
        React.SetStateAction<LocationFormData | undefined>
    >;
    accountFormData: AccountFormData | undefined;
    locationsFormData: LocationFormData | undefined;
}

const initialContext: EssentialPlanContext = {
    activeStepIndex: 0,
    setActiveStepIndex: stub,
    setAccountFormData: stub,
    setLocationsFormData: stub,
    accountFormData: undefined,
    locationsFormData: {
        planType: PaymentPlan.Monthly,
        locations: [
            {
                name: undefined,
                country: undefined,
                town: undefined,
                postCode: undefined,
                size: undefined,
            },
        ],
    },
};

export interface NavStatusItem {
    title: string;
    subtitle: string;
}

export const EssentialPlanContext =
    createContext<EssentialPlanContext>(initialContext);

function EssentialPlanView(): JSX.Element {
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const [accountFormData, setAccountFormData] = useState<
        AccountFormData | undefined
    >();
    const [locationsFormData, setLocationsFormData] = useState<
        LocationFormData | undefined
    >();

    const context = useContext(EssentialPlanContext);
    const { t } = useTranslation();

    const navStatusItems: NavStatusItem[] = useMemo(() => {
        return [
            {
                title: t("nav_create_account_title"),
                subtitle: t("nav_create_account_subtitle"),
            },
            {
                title: t("nav_locations_title"),
                subtitle: t("nav_locations_subtitle"),
            },
            {
                title: t("nav_payment_title"),
                subtitle: "",
            },
        ];
    }, []);

    return (
        <EssentialPlanContext.Provider
            value={{
                activeStepIndex: activeStepIndex,
                setActiveStepIndex: setActiveStepIndex,
                setAccountFormData: setAccountFormData,
                setLocationsFormData: setLocationsFormData,
                accountFormData: accountFormData,
                locationsFormData: locationsFormData,
            }}
        >
            <div className={"essential-plan"}>
                <div className={"essential-plan__nav"}>
                    <div className={"essential-plan__nav-title"}>
                        {navStatusItems[activeStepIndex].title}
                    </div>
                    <div className={"essential-plan__nav-subtitle"}>
                        {navStatusItems[activeStepIndex].subtitle}
                    </div>
                    <div className={"essential-plan__nav-status"}>
                        <div className={"essential-plan__nav-status-indicator"}>
                            <Sprite
                                name={
                                    activeStepIndex === 0
                                        ? "circleFilled"
                                        : "circleCompleted"
                                }
                            />
                            <div
                                className={
                                    "essential-plan__nav-status-separator"
                                }
                            ></div>
                            <Sprite
                                name={
                                    activeStepIndex === 1
                                        ? "circleFilled"
                                        : activeStepIndex < 1
                                        ? "circle"
                                        : "circleCompleted"
                                }
                            />
                            <div
                                className={
                                    "essential-plan__nav-status-separator"
                                }
                            ></div>
                            <Sprite
                                name={
                                    activeStepIndex === 2
                                        ? "circleFilled"
                                        : "circle"
                                }
                            />
                        </div>
                        <div className={"essential-plan__nav-status-text"}>
                            <span>{navStatusItems[0].title}</span>
                            <span>{navStatusItems[1].title}</span>
                            <span>{navStatusItems[2].title}</span>
                        </div>
                    </div>
                </div>

                <div className={"essential-plan__content"}>
                    {activeStepIndex === 0 ? (
                        <CreateAccountView />
                    ) : (
                        <LocationsView />
                    )}
                </div>
            </div>
        </EssentialPlanContext.Provider>
    );
}

export default EssentialPlanView;
