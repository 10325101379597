export interface VenueLocation {
    name: string | undefined;
    country: string | undefined;
    town: string | undefined;
    postCode: string | undefined;
    size: LocationSize | undefined;
}

export enum LocationSize {
    Below50,
    Above50,
}
