import "./PaymentSuccessView.scss";
import React from "react";
import Sprite from "../../../assets/icons/Sprite";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PaymentSuccessView(): JSX.Element {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className={"payment-success"}>
            <div className={"essential-plan__nav"}>
                <div className={"essential-plan__nav-title"}>
                    {t("payment_success_title")}
                </div>
                <div className={"essential-plan__nav-status"}>
                    <div className={"essential-plan__nav-status-indicator"}>
                        <Sprite name={"circleCompleted"} />
                        <div
                            className={
                                location.state && location.state.showLocation
                                    ? "essential-plan__nav-status-separator"
                                    : "commercial-plan__nav-status-separator"
                            }
                        ></div>
                        {location.state && location.state.showLocation && (
                            <>
                                <Sprite name={"circleCompleted"} />
                                <div
                                    className={
                                        "essential-plan__nav-status-separator"
                                    }
                                ></div>
                            </>
                        )}
                        <Sprite name={"circleCompleted"} />
                    </div>
                    <div className={"essential-plan__nav-status-text"}>
                        <span>{t("nav_create_account_title")}</span>
                        {location.state && location.state.showLocation && (
                            <span>{t("nav_locations_title")}</span>
                        )}
                        <span>{t("nav_locations_payment")}</span>
                    </div>
                </div>
            </div>

            <div className={"payment-success__content"}>
                <div>{t("payment_success_thanks_title")}</div>
                <div>{t("payment_success_thanks_subtitle")}</div>
            </div>
        </div>
    );
}

export default PaymentSuccessView;
